import React from "react";
import { Link } from "gatsby";
import i18n from "../i18n/i18n";

// markup
const NotFoundPage = ({ pageContext }) => {
  const t = (key: string, options?: object) => {
    return i18n.t(key, { ...options, lng: pageContext.lang });
  };
  return (
    <main className="fixed inset-0 flex items-center justify-center">
      <div className="text-center p-10 sm:w-3/5 lg:w-2/4">
        <div className="notfound-404">
          <h3 className="text-3xl">{t("404.title")}</h3>
          <h1 className="text-9xl font-extrabold tracking-tighter">
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </h1>
        </div>
        <h2 className="text-3xl">{t("404.description")}</h2>
        <Link to={t("404.link")} className="mt-10 block underline hover:text-blue-600">
          {t("404.action")}
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
